import React from "react";

import type { LucideIcon, LucideProps } from "lucide-react";
import { ArrowUp } from "lucide-react";
import tw from "twin.macro";

import type { CircleProgressMetricProps } from "@/app/core/charts/CircleProgressMetric";
import { CircleProgressMetric } from "@/app/core/charts/CircleProgressMetric";
import { Flex } from "@/app/core/ui/components/Flex";
import { Text } from "@/app/core/ui/components/Text";

type RegularStatProps = {
  type?: "regular";
  value: string | JSX.Element;
  hover?: string;
  indicator?: {
    solid?: boolean;
    diff: string;
    isPositive: boolean;
    icon?: LucideIcon | null;
    iconProps?: LucideProps;
  };
  extra?: string | JSX.Element;
};

type CircleProgressProps = CircleProgressMetricProps & {
  type: "circleProgress";
};

interface StatsProps extends React.ComponentProps<typeof Flex> {
  items: ({
    title: string;
  } & (RegularStatProps | CircleProgressProps))[];
}

export const Stats: React.FC<StatsProps> = ({ items, ...props }) => {
  return (
    <Flex
      tw="border-b border-gray-200 py-3 flex-wrap gap-y-4 divide-x divide-gray-300 relative"
      {...props}
    >
      {items.map((item, i) => (
        <Flex key={item.title + i} tw="flex-col gap-0.25 flex-1 px-3">
          {item.type === "circleProgress" ? (
            <CircleProgressMetric {...item} />
          ) : (
            <>
              <Text weight="bold" color="gray-600" nowrap>
                {item.title}
              </Text>
              <Flex tw="items-end flex-shrink-0">
                <Text weight="bolder" tw="text-[2.3rem]" title={item.hover}>
                  {item.value}
                </Text>

                {item.extra && typeof item.extra === "string" ? (
                  <Text
                    as="span"
                    size="md"
                    tw="mb-0.5 ml-0.75"
                    color="gray-600"
                    weight="bold"
                  >
                    {item.extra}
                  </Text>
                ) : (
                  item.extra
                )}

                {item.indicator && (
                  <Flex
                    tw="items-center mb-0.5 text-sm flex-shrink-0 ml-1"
                    css={{
                      ...(item.indicator.isPositive
                        ? tw`text-[#26C81E]`
                        : tw`text-[#F22323]`),
                      ...(item.indicator.solid
                        ? tw`px-0.5 py-0.25 text-xs rounded-md`
                        : {}),
                      ...(item.indicator.solid
                        ? item.indicator.isPositive
                          ? tw`bg-[#E9FBE9]`
                          : tw`bg-red-200`
                        : {}),
                    }}
                  >
                    {item.indicator.icon !== undefined ? (
                      item.indicator.icon !== null &&
                      React.cloneElement(<item.indicator.icon />, {
                        ...item.indicator.iconProps,
                        size: 14,
                        strokeWidth: 2.2,
                        style: {
                          marginRight: "0.4rem",
                          ...item.indicator.iconProps?.style,
                        },
                      })
                    ) : (
                      <ArrowUp
                        size="14"
                        strokeWidth={2.2}
                        tw="mr-0.25"
                        css={item.indicator.isPositive ? {} : tw`rotate-180`}
                      />
                    )}

                    <Text weight="bold">{item.indicator.diff}</Text>
                  </Flex>
                )}
              </Flex>
            </>
          )}
        </Flex>
      ))}
    </Flex>
  );
};
