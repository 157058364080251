import React, { useMemo } from "react";

import { Check, CircleDot, Clock, X } from "lucide-react";

import { Badge } from "@/app/core/ui/components/Badge";
import { Flex } from "@/app/core/ui/components/Flex";
import type { PrismaDocumentStatus } from "@obd/common";

interface DocumentStatusProps {
  status: PrismaDocumentStatus;
  genderLetter?: string;
  name?: string;
}

export const DocumentStatus: React.FC<DocumentStatusProps> = ({
  status,
  genderLetter = "o",
  name,
}) => {
  const data = useMemo(() => {
    let statusName = "?";
    let fg = "#DDDDDD";
    let bg = "#DDDDDD";
    let icon = CircleDot;

    if (status === "open") {
      statusName = "En proceso";
      bg = "#F9F1FE";
      fg = "#793AAF";
      icon = Clock;
    } else if (status === "canceled") {
      statusName = `Cancelad${genderLetter}`;
      bg = "#FFEFEF";
      fg = "#CD2B31";
      icon = X;
    } else if (status === "closed") {
      bg = "#E9F9EE";
      fg = "#18794E";
      statusName = `Aceptad${genderLetter}`;
      icon = Check;
    }

    if (name) {
      statusName = name;
    }

    return { statusName, bg, fg, icon };
  }, [genderLetter, name, status]);

  return (
    <Flex>
      <Badge
        size="sm"
        weight="medium"
        css={{
          color: data.fg,
          backgroundColor: data.bg,
          border: `1px solid ${data.fg}20`,
        }}
      >
        {React.cloneElement(<data.icon />, {
          size: "12",
        })}
        {data.statusName}
      </Badge>
    </Flex>
  );
};
