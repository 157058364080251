import React, { useState } from "react";

import type { Control } from "react-hook-form";

import { Modal } from "@/app/core/display/modal/components/Modal";
import { useModal } from "@/app/core/display/modal/store/useModal";
import { useToast } from "@/app/core/display/toast/store/useToast";
import { Input } from "@/app/core/forms/components/Input";
import { Select } from "@/app/core/forms/components/Select";
import { Form } from "@/app/core/forms/Form";
import { useApiMutation } from "@/app/core/http/hooks/useApiMutation";
import { useApiRefetch } from "@/app/core/http/hooks/useApiRefetch";
import { Button } from "@/app/core/ui/components/Button";
import { Flex } from "@/app/core/ui/components/Flex";
import { getOfferCloseReasonDisplayName } from "@/app/crm/partner/documents/utils/document-status";
import { PrismaOfferCloseReason, offerSchema, typedObjectKeys } from "@obd/common";

interface CloseOfferModalProps {}

export const CloseOfferModal: React.FC<CloseOfferModalProps> = () => {
  const [closeReason, setCloseReason] = useState<PrismaOfferCloseReason>();

  const closeReasons = typedObjectKeys(PrismaOfferCloseReason).map((r) => {
    return {
      value: r,
      name: getOfferCloseReasonDisplayName(r),
    };
  });

  const closeOffer = useApiMutation("closeOffer");
  const refetch = useApiRefetch();

  return (
    <Modal
      name="closeOffer"
      title="Cerrar oferta"
      description="Selecciona el motivo de cierre para cerrar esta oferta en SAP"
      tw="min-w-[50rem]"
    >
      {({ id, reference }) => (
        <Flex tw="flex-col gap-2">
          <Input
            name="noop"
            type="text"
            label="Número oferta"
            disabled
            value={String(reference)}
          />

          <Form
            schema={offerSchema.close}
            onSubmit={async (body) => {
              const offer = await closeOffer.mutateAsync({
                body: { closeReason: body.closeReason ?? closeReason },
                params: { offerId: id },
              });

              refetch("getPartnerOffers");
              refetch("getCommercialOffers");

              useToast.getState().showToast({
                type: "success",
                message: `Oferta ${offer.reference} cerrada con estado ${
                  offer.closeReason
                    ? getOfferCloseReasonDisplayName(offer.closeReason)
                    : "-"
                }`,
              });

              useModal.getState().closeModal();
            }}
          >
            {({ control, isLoading }) => (
              <>
                <Select
                  name="closeReason"
                  defaultValue="Seleccionar motivo de cierre"
                  label="Motivo de cierre"
                  options={closeReasons}
                  onChange={setCloseReason}
                  control={control as Control<any>}
                />

                <Flex tw="items-center justify-end gap-0.5 mt-3">
                  <Button
                    type="button"
                    theme="white"
                    onClick={() => useModal.getState().closeModal()}
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    theme="error"
                    isLoading={isLoading}
                    disabled={isLoading || !closeReason}
                  >
                    Cerrar oferta
                  </Button>
                </Flex>
              </>
            )}
          </Form>
        </Flex>
      )}
    </Modal>
  );
};
