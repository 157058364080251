import type { CellContext, ColumnDefTemplate } from "@tanstack/react-table";

import type { DataTableColumnDef } from "@/app/core/datatable/datatable.types";
import type { TableFilters, TableFiltersColumn } from "@obd/common";

type ResolvableData<D> = {
  [K in TableFiltersColumn<D> as K["id"]]+?:
    | K["id"]
    | {
        accessorKey?: keyof D;
        cell?: ColumnDefTemplate<CellContext<D, any>>;
        hide?: boolean;
        meta?: DataTableColumnDef<D>["meta"];
      }
    | {
        accessorFn?: (d: D) => any;
        cell?: ColumnDefTemplate<CellContext<D, any>>;
        hide?: boolean;
        meta?: DataTableColumnDef<D>["meta"];
      };
};

export const resolveRenderer = <D>(
  filters: TableFilters<D> | undefined,
  data: ResolvableData<D>
): DataTableColumnDef<D>[] | null => {
  if (!filters) {
    return null;
  }

  const cols = Object.fromEntries(
    Object.entries<any>(data).map(([key, value]) => [
      key,
      typeof value === "string"
        ? { accessorKey: value }
        : !value.accessorKey && !value.accessorFn
        ? { accessorKey: key, ...value }
        : value,
    ])
  ) as ResolvableData<D>;

  return filters.columns.flatMap((c) =>
    !(c.id in cols)
      ? []
      : {
          id: c.id,
          header: c.header,
          hide: !c.visible,
          size: c.width,
          enableSorting: c.sortable,
          ...cols[c.id],
        }
  ) as DataTableColumnDef<D>[];
};
