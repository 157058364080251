import React from "react";

import type { QueryMethods } from "@/app/core/http/api-client.types";
import { Card } from "@/app/core/ui/components/Card";
import { Flex } from "@/app/core/ui/components/Flex";
import { QuantityIndicator } from "@/app/core/ui/components/QuantityIndicator";
import { Text } from "@/app/core/ui/components/Text";
import type { PartnerDocumentListProps } from "@/app/crm/partner/documents/components/PartnerDocumentList";
import { PartnerDocumentList } from "@/app/crm/partner/documents/components/PartnerDocumentList";

interface DocumentGroupCardProps {
  title: string;
  documents: PartnerDocumentListProps<QueryMethods> & { count?: number };
  showIfEmpty?: boolean;
}

export const DocumentGroupCard: React.FC<DocumentGroupCardProps> = ({
  title,
  documents,
  showIfEmpty = false,
}) => {
  if (!documents || (Array.isArray(documents) && !documents.length && !showIfEmpty)) {
    return null;
  }

  return (
    <Card>
      <Flex tw="flex-col w-full">
        <Flex tw="gap-1 items-center mb-2">
          <Text size="xl" weight="bold">
            {title}
          </Text>
          <QuantityIndicator
            tw="bg-gray-200 mt-0.25"
            size="sm"
            weight="bolder"
            color="gray-700"
          >
            {documents.count ?? 0}
          </QuantityIndicator>
        </Flex>

        <PartnerDocumentList noFeedback {...documents} />
      </Flex>
    </Card>
  );
};
