import { create } from "zustand";
import { combine } from "zustand/middleware";

import type {
  AvailableModals,
  ModalState as ModalProps,
} from "@/app/core/display/modal/modal.types";

export const useModal = create(
  combine(
    {
      activeModal: null as
        | {
            [K in AvailableModals]: { name: K; props: ModalProps[K] };
          }[AvailableModals]
        | null,
    },
    (set) => ({
      closeModal: () => set({ activeModal: null }),
      openModal: <T extends AvailableModals>(
        name: T,
        ...p: ModalProps[T] extends never ? [] : [ModalProps[T]]
      ) => set({ activeModal: { name: name as any, props: p?.[0] as any } }),
    })
  )
);
