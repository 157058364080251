import type { PrismaOfferCloseReason } from "@obd/common";

const valueToNameMap: Record<PrismaOfferCloseReason, string> = {
  price: "Precio",
  deliveryTime: "Plazo de entrega de la oferta",
  availability: "Disponibilidad",
  financial: "Motivos financieros",
  tenderNotAwarded: "Obra no adjudicada",
  productsChange: "Cambio marca/materiales",
  partialAcceptance: "Aceptación parcial",
  other: "Otros",
};

export const getOfferCloseReasonDisplayName = (closeReason: PrismaOfferCloseReason) => {
  return valueToNameMap[closeReason];
};
