import React from "react";

import { keyframes } from "@stitches/react";
import tw, { theme } from "twin.macro";

import { Absolute } from "@/app/core/ui/components/Absolute";
import { Box } from "@/app/core/ui/components/Box";
import { Flex } from "@/app/core/ui/components/Flex";
import { Text } from "@/app/core/ui/components/Text";
import { number } from "@/app/core/utils";

const progress = keyframes({
  "0%": {
    strokeDasharray: "0 100",
  },
});

export interface CircleProgressMetricProps
  extends Omit<React.SVGProps<SVGSVGElement>, "target"> {
  title: string;
  value: number;
  target: number;
  delta?: number;
  foreground?: string;
  background?: string;
  reverseTitle?: boolean;
}

export const CircleProgressMetric: React.FC<CircleProgressMetricProps> = ({
  title,
  value,
  target,
  delta,
  foreground = theme("colors.success.DEFAULT"),
  background = theme("colors.gray.150"),
  width = 100,
  reverseTitle = false,
  ...props
}) => {
  const percentage = target === 0 ? 0 : (value * 100) / target;
  const dashArray = Math.ceil((percentage * 70) / 100);

  return (
    <Flex
      tw="flex-col items-center gap-1"
      css={{ ...(reverseTitle && tw`flex-col-reverse`) }}
    >
      <Text weight="bold" color="gray-600" nowrap>
        {title}
      </Text>

      <Box tw="relative">
        <svg
          width={width}
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M5.12399 27.3549C2.64291 23.94 1.62002 19.6793 2.28034 15.5102C2.94066 11.3411 5.2301 7.60513 8.645 5.12406C12.0599 2.64298 16.3205 1.6201 20.4896 2.28041C24.6587 2.94073 28.3947 5.23017 30.8758 8.64507C33.3569 12.06 34.3798 16.3206 33.7195 20.4897C33.0591 24.6588 30.7697 28.3948 27.3548 30.8759C23.9399 33.357 19.6792 34.3798 15.5102 33.7195C11.3411 33.0592 7.60506 30.7698 5.12399 27.3549Z"
            stroke={background}
            strokeWidth="2.8"
            strokeLinecap="round"
            strokeDasharray="70 100"
          />
          <path
            d="M5.12399 27.3549C2.64291 23.94 1.62002 19.6793 2.28034 15.5102C2.94066 11.3411 5.2301 7.60513 8.645 5.12406C12.0599 2.64298 16.3205 1.6201 20.4896 2.28041C24.6587 2.94073 28.3947 5.23017 30.8758 8.64507C33.3569 12.06 34.3798 16.3206 33.7195 20.4897C33.0591 24.6588 30.7697 28.3948 27.3548 30.8759C23.9399 33.357 19.6792 34.3798 15.5102 33.7195C11.3411 33.0592 7.60506 30.7698 5.12399 27.3549Z"
            stroke={
              dashArray === 0
                ? background
                : delta !== undefined && value - delta < 0
                ? "#FFD34E"
                : foreground
            }
            strokeWidth="2.8"
            strokeLinecap="round"
            strokeDasharray={`${dashArray > 70 ? 70 : dashArray} 100`}
            css={{
              animation: `${progress} 1s ease-out forwards`,
              filter:
                dashArray > 0
                  ? delta !== undefined && value - delta < 0
                    ? "drop-shadow(0px 0px 1.2px rgba(255, 211, 78, 0.314))"
                    : `drop-shadow(0px 0px 1.2px rgba(49, 253, 90, 0.58))`
                  : undefined,
            }}
          />
        </svg>

        <Absolute align="bottomCenter">
          <Flex center tw="flex-col bottom-0.5! gap-0.75">
            <Text
              weight="bolder"
              tw="[line-height: 1] text-[2.75rem]"
              css={{
                ...(value >= 100 && tw`text-[2.45rem]`),
                ...(value >= 1000 && tw`text-[2.1rem]`),
                ...(value >= 10000 && tw`text-[1.7rem]`),
              }}
            >
              {number.delimited(value)}
            </Text>
            <Box tw="h-[0.1rem] bg-gray-300 w-full max-w-[3rem]" />
            <Text color="gray-600" weight="bold" size="md" tw="[line-height: 1]">
              {number.delimited(target)}
            </Text>
          </Flex>
        </Absolute>
      </Box>

      {delta !== undefined && (
        <Flex
          tw="items-center text-sm flex-shrink-0 px-0.5 py-0.25 text-xs rounded-md font-bold gap-0.5 -mt-0.5"
          css={{
            ...(value - delta === 0
              ? tw`text-gray-700 bg-gray-150`
              : value - delta > 0
              ? tw`text-[#26C81E] bg-[#E9FBE9]`
              : tw`text-[#d0680c] bg-[#FFF2E4]`),
          }}
        >
          {value - delta > 0 ? "+" : ""}
          {value - delta} vs anterior
        </Flex>
      )}
    </Flex>
  );
};
