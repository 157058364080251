import React, { useEffect, useState } from "react";

import { Dialog } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";

import type { AvailableModals, ModalState } from "@/app/core/display/modal/modal.types";
import { useModal } from "@/app/core/display/modal/store/useModal";
import { usePageScroll } from "@/app/core/display/page/hooks/usePageScroll";
import { Box } from "@/app/core/ui/components/Box";
import { Text } from "@/app/core/ui/components/Text";

type GetChildrenType<T extends AvailableModals> = ModalState[T] extends never
  ? React.ReactNode
  : (props: ModalState[T]) => JSX.Element;

export interface ModalProps<T extends AvailableModals>
  extends Omit<React.HTMLProps<HTMLDivElement>, "title" | "as" | "children" | "ref"> {
  name: T;
  onClose?: () => void;
  title?: string | JSX.Element;
  description?: string | JSX.Element;
  children: GetChildrenType<T>;
}

export const Modal = <T extends AvailableModals>({
  name,
  onClose,
  title,
  description,
  children,
  ...props
}: ModalProps<T>): JSX.Element => {
  const [open, setOpen] = useState(false);
  const { activeModal, closeModal } = useModal();
  const setCanScroll = usePageScroll();

  useEffect(() => {
    const isOpen = activeModal?.name === name;
    setOpen(isOpen);
    setCanScroll(!isOpen);
  }, [activeModal, name, setCanScroll]);

  return (
    <AnimatePresence>
      {open && (
        <Dialog
          static
          as={motion.div as any}
          open={open}
          onClose={() => {
            onClose?.();
            closeModal();
          }}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
            zIndex: 50,
          }}
          layout
          initial={{ opacity: 0, y: -10, scale: 1 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          exit={{ opacity: 0, y: -10, scale: 1 }}
          transition={{
            type: "spring",
            bounce: 0.4,
            duration: 0.4,
          }}
        >
          <Box tw="fixed inset-0 bg-[rgba(13, 15, 15, 0.24)]" aria-hidden="true" />

          <Dialog.Panel
            tw="bg-white border border-gray-300 rounded-2xl relative mt-[7%] shadow-xl"
            {...props}
          >
            {(title || description) && (
              <Box tw="px-3 py-2 border-b border-gray-200">
                {title && (
                  <Dialog.Title as={Text} weight="bold" tw="text-2xl">
                    {title}
                  </Dialog.Title>
                )}

                {description && (
                  <Dialog.Description as={Text} color="gray-700" tw="mt-0.75 text-md">
                    {description}
                  </Dialog.Description>
                )}
              </Box>
            )}

            <Box tw="p-3">
              {typeof children === "function"
                ? activeModal && children(activeModal?.props as any)
                : children}
            </Box>
          </Dialog.Panel>
        </Dialog>
      )}
    </AnimatePresence>
  );
};
