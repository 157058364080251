import { useCallback, useRef, useState } from "react";

import { Listbox } from "@headlessui/react";
import type { UseResizeObserverCallback } from "@react-hookz/web";
import { useDebouncedCallback, useResizeObserver } from "@react-hookz/web";
import { AnimatePresence, motion } from "framer-motion";
import { ChevronsUpDown } from "lucide-react";
import type { UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";
import tw from "twin.macro";

import { Box } from "@/app/core/ui/components/Box";
import { Flex } from "@/app/core/ui/components/Flex";
import { Text } from "@/app/core/ui/components/Text";

type Option = { value: string; name: string | number | JSX.Element };
interface SelectProps<T extends Option> extends UseControllerProps {
  defaultValue: string;
  options: T[];
  label?: string;
  onChange?: (v: T["value"]) => void;
  disabled?: boolean;
  disableAutoHeight?: boolean;
  required?: boolean;
}

// eslint-disable-next-line comma-spacing
export const Select = <T extends Option>({
  options,
  defaultValue,
  label,
  onChange,
  disabled = false,
  disableAutoHeight = false,
  required = true,
  ...props
}: SelectProps<T>) => {
  const { field, fieldState } = useController(props);

  const handleChange = useCallback(
    (ev: any) => {
      if (!disabled) {
        field.onChange(ev);
        onChange?.(ev.value);
      }
    },
    [disabled, field, onChange]
  );

  const optionsBoxRef = useRef<HTMLDivElement>(null);
  const [optionsHeight, setOptionsHeight] = useState<number>();
  const computeHeight: UseResizeObserverCallback = useDebouncedCallback(
    () => {
      if (!optionsBoxRef.current || disableAutoHeight) {
        return;
      }

      const boxRect = optionsBoxRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const distanceToBottom = windowHeight - boxRect.bottom;
      if (distanceToBottom < 0) {
        setOptionsHeight(boxRect.height - Math.abs(distanceToBottom) - 50);
      }
    },
    [disableAutoHeight],
    500
  );

  useResizeObserver(document.documentElement, computeHeight);

  return (
    <Flex tw="flex-col">
      <Listbox value={field.value ?? defaultValue} onChange={handleChange}>
        {({ open }) => (
          <>
            {label && (
              <Listbox.Label tw="ml-0.5 font-medium text-md mb-1">
                {label} {required && <span tw="text-red-600">*</span>}
              </Listbox.Label>
            )}

            <Box
              tw="relative z-30 bg-white text-gray-800 text-md"
              css={{
                ...(open && !disabled
                  ? tw`rounded-t-md border-t border-l border-r`
                  : tw`rounded-md border`),
                ...(fieldState.error ? tw`border-red-600` : tw`border-gray-300`),
              }}
            >
              <Listbox.Button
                tw="px-1.5 py-1 w-full flex items-center justify-between border-b border-gray-150"
                css={{ ...(disabled ? tw`cursor-not-allowed` : {}) }}
                onClick={() => computeHeight("" as any)}
              >
                {field.value?.name ??
                  (typeof defaultValue === "string"
                    ? defaultValue
                    : (defaultValue as any).name)}
                <ChevronsUpDown size="16" />
              </Listbox.Button>

              <AnimatePresence>
                {open && !disabled && (
                  <motion.div
                    ref={optionsBoxRef}
                    layout
                    initial={{ opacity: 0, y: -10, scale: 1, pointerEvents: "none" }}
                    animate={{ opacity: 1, y: 0, scale: 1, pointerEvents: "auto" }}
                    exit={{ opacity: 0, y: -10, scale: 1, pointerEvents: "none" }}
                    transition={{
                      type: "spring",
                      bounce: 0.4,
                      duration: 0.4,
                    }}
                    style={{
                      position: "absolute",
                      zIndex: 30,
                      top: "100%",
                      left: -1,
                      width: "calc(100% + 2px)",
                      height: optionsHeight,
                      overflowY: "auto",
                    }}
                  >
                    <Listbox.Options
                      static
                      tw="p-0.5 bg-white border-l border-r border-b border-gray-300 rounded-b-md shadow-lg"
                    >
                      {options.map((option) => (
                        <Listbox.Option
                          key={"option" + props.name + option.value + option.name}
                          value={option}
                          tw="py-0.75 px-1 hover:bg-gray-100 rounded-lg w-full text-left cursor-pointer"
                        >
                          {option.name}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </motion.div>
                )}
              </AnimatePresence>
            </Box>
          </>
        )}
      </Listbox>
      {fieldState.error ? (
        <Text size="sm" color="red-600" tw="ml-0.5">
          {fieldState.error.message}
        </Text>
      ) : null}
    </Flex>
  );
};
