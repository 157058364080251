import React, { useMemo } from "react";

import { XSquareIcon } from "lucide-react";

import { DataTable } from "@/app/core/datatable/DataTable";
import type { DataTableColumnDef } from "@/app/core/datatable/datatable.types";
import { useTableFilters } from "@/app/core/datatable/filters/hooks/useTableFilters";
import { resolveRenderer } from "@/app/core/datatable/utils";
import { useModal } from "@/app/core/display/modal/store/useModal";
import type { apiClient } from "@/app/core/http/api-client";
import type { QueryMethods } from "@/app/core/http/api-client.types";
import { useInfiniteApiQuery } from "@/app/core/http/hooks/useInfiniteApiQuery";
import { Flex } from "@/app/core/ui/components/Flex";
import { Loader } from "@/app/core/ui/components/Loader";
import { Text } from "@/app/core/ui/components/Text";
import { date, number } from "@/app/core/utils";
import { CloseOfferModal } from "@/app/crm/partner/documents/components/CloseOfferModal";
import { DocumentStatus } from "@/app/crm/partner/documents/components/DocumentStatus";
import { getOfferCloseReasonDisplayName } from "@/app/crm/partner/documents/utils/document-status";
import type { IOffer, IPartnerDocumentType } from "@obd/common";

export interface PartnerDocumentListProps<Q extends QueryMethods> {
  queryKey: Q;
  documentKind: "offer" | "order" | "invoice";
  request: Partial<Parameters<(typeof apiClient)["query"][Q]>[0]>;
  count?: number;
  noFeedback?: boolean;
  hidePartner?: boolean;
  exportable?: boolean;
  enableOfferClosing?: boolean;
}

export const PartnerDocumentList = <Q extends QueryMethods>({
  queryKey,
  documentKind,
  request,
  count,
  noFeedback = false,
  hidePartner = false,
  exportable = false,
  enableOfferClosing = false,
}: PartnerDocumentListProps<Q>) => {
  const filters = useTableFilters({
    filtersQuery: "getDocumentFilters",
    params: {
      limit: 10,
      sort: { entity: "createdAt", direction: "desc" },
    },
  });

  const query = useInfiniteApiQuery(
    [queryKey as "getPartnerOffers", filters.queryKey, JSON.stringify(request)],
    {
      request: {
        ...(request as any),
        query: { ...filters.params, ...(request as any).query },
      },
      enabled: !filters.isLoading,
      exportable,
    }
  );

  const columns = useMemo<DataTableColumnDef<IPartnerDocumentType>[]>(() => {
    const cols = resolveRenderer<IPartnerDocumentType>(filters.filters, {
      reference: "reference",
      status: {
        accessorFn: (d) => d,
        cell: (d) => {
          const v = d.getValue<IOffer>();
          return (
            <DocumentStatus
              status={v.status}
              genderLetter="a"
              name={
                v.closeReason ? getOfferCloseReasonDisplayName(v.closeReason) : undefined
              }
            />
          );
        },
      },
      partner: {
        accessorFn: (d) => [d.partner.code, d.partner.name].join(" - "),
        hide: hidePartner,
      },
      partnerReference: "partnerReference",
      totalAmount: {
        accessorFn: (d) => number.toCurrency(d.totalAmount),
        meta: {
          css: { textAlign: "right" },
        },
      },
      createdAt: {
        accessorFn: (d) => date.format(d.createdAt, "dd/MM/yyyy"),
      },
    });

    if (!cols) {
      return [];
    }

    return [
      { id: "inner", accessorFn: (d) => d.details, hide: true },
      ...cols,
      {
        id: "actions",
        accessorFn: (d) => d,
        header: "Acciones",
        hide: !enableOfferClosing,
        size: 50,
        meta: {
          actions: (d) => [
            {
              icon: <XSquareIcon size="14" tw="text-red-600" />,
              title: <Text color="red-600">Cerrar oferta</Text>,
              disabled: d.getValue<IOffer>().status !== "open",
              action: () =>
                useModal.getState().openModal("closeOffer", {
                  id: d.getValue<IOffer>().id,
                  reference: d.getValue<IOffer>().reference,
                }),
            },
          ],
        },
      },
    ];
  }, [enableOfferClosing, filters.filters, hidePartner]);

  return (
    <>
      {query.isInitialLoading && !noFeedback ? (
        <Flex center tw="py-3">
          <Loader />
        </Flex>
      ) : (
        <DataTable
          data={query}
          totalCount={count}
          hideHeader
          smaller
          initialState={{ columns: filters.columnState }}
          columns={columns}
          renderInnerRow={(data: IOffer["details"], colSpan, virtual) => (
            <tr
              tw="bg-gray-50 border-x border-gray-150 shadow-md"
              ref={virtual.measureRef}
            >
              <td colSpan={colSpan}>
                <table width="100%">
                  <thead>
                    <tr tw="bg-gray-150 border-b border-gray-300 divide-x divide-gray-300">
                      {(
                        [
                          ["Código"],
                          ["Producto"],
                          ["Cantidad", "right"],
                          ["Precio unitario", "right"],
                          ["Descuento", "right"],
                          ["Cantidad pendiente", "right", documentKind !== "order"],
                          ["Total pendiente", "right", documentKind !== "order"],
                          ["Total", "right"],
                        ] as const
                      ).map(([header, align, hide = false]) =>
                        hide ? null : (
                          <th
                            key={header}
                            tw="px-1 py-0.75 text-gray-700 text-sm whitespace-nowrap"
                            style={{ textAlign: align ?? "left" }}
                          >
                            {header}
                          </th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody tw="divide-y divide-gray-200">
                    {!data.length && (
                      <tr>
                        <td
                          colSpan={5}
                          tw="px-1 py-0.75 text-sm text-center text-gray-700"
                        >
                          No hay resultados
                        </td>
                      </tr>
                    )}
                    {data.map((detail) => (
                      <tr
                        key={`detail-${detail.id}-${detail.productCode}`}
                        tw="divide-x divide-gray-200"
                      >
                        {(
                          [
                            [detail.productCode, 100],
                            [detail.productName, 600],
                            [number.delimited(detail.quantity ?? 0), 100, "right"],
                            [number.toCurrency(detail.price), 100, "right"],
                            [number.delimited(detail.discount) + " %", 100, "right"],
                            [
                              number.delimited(detail.openQuantity ?? 0),
                              100,
                              "right",
                              documentKind !== "order",
                            ],
                            [
                              number.toCurrency(detail.totalOpenPrice),
                              100,
                              "right",
                              documentKind !== "order",
                            ],
                            [number.toCurrency(detail.totalPrice), 100, "right"],
                          ] as const
                        ).map(([cell, width, align, hide = false], i) =>
                          hide ? null : (
                            <td
                              key={"" + detail.id + detail.line + i + cell}
                              style={{ width, textAlign: align }}
                              tw="px-1 py-0.75 text-sm whitespace-nowrap"
                            >
                              {cell}
                            </td>
                          )
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
            </tr>
          )}
        />
      )}

      <CloseOfferModal />
    </>
  );
};
